import * as React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react"
import BlogLayout from "../components/word/BlogLayout"
import { ChevronRightIcon, Icon } from "@chakra-ui/icons"
import { MdOutlineToday } from "react-icons/md"
import WordTag from "../components/word/WordTag"
import { CodeBlock } from "../components/CodeBlock"

export default function WordPost({ pageContext }: any): JSX.Element {
  const { mdx } = pageContext
  const { frontmatter, body } = mdx
  const components = { pre: CodeBlock }
  let dtpost = new Date(frontmatter.date)
  let date = dtpost.toDateString()
  let monthstr = frontmatter.month.toString().padStart(2, "0")
  let daystr = frontmatter.day.toString().padStart(2, "0")
  return (
    <BlogLayout pageTitle={frontmatter.title}>
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        pt={6}
        pl={4}
        pb={1}
      >
        <BreadcrumbItem>
          <BreadcrumbLink href="/word">word</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink href={`/word/${frontmatter.year}`}>
            {frontmatter.year}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink href={`/word/${frontmatter.year}/${monthstr}`}>
            {monthstr}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink
            href={`/word/${frontmatter.year}/${monthstr}/${daystr}`}
          >
            {daystr}
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href={"#"}>{frontmatter.slug}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex
        as={"article"}
        className="blog-post"
        px={4}
        pt={1}
        pb={2}
        flexDirection={"column"}
      >
        <Heading as={"h1"} id={"title"}>
          {frontmatter.title}
        </Heading>

        <Flex alignItems={"center"} className={"date-categories"} mt={1}>
          <Icon as={MdOutlineToday} mr={1} />
          <Text className={"datetime"} fontSize={"sm"}>
            {date}
          </Text>
          {frontmatter.categories && (
            <Text fontSize={"sm"} px={1}>
              in
            </Text>
          )}
          {frontmatter.categories &&
            frontmatter.categories.map((category: string, idx: number) => {
              // the empty space returned is significant for text-based browsers/crawlers
              return (
                <span key={idx}>
                  <WordTag
                    text={category}
                    kind={"category"}
                    size={"sm"}
                    mr={1}
                  />{" "}
                </span>
              )
            })}
        </Flex>

        <Flex alignItems={"center"} className={"tags"} pt={1}>
          {frontmatter.tags &&
            frontmatter.tags.map((tag: string, idx: number) => {
              return (
                <span key={idx}>
                  <WordTag text={tag} kind={"tag"} size={"sm"} mr={1} />{" "}
                </span>
              )
            })}
        </Flex>

        <Heading id={"subtitle"} fontSize={"1.75rem"} py={2}>
          {frontmatter.subtitle}
        </Heading>
        <Box className="blog-post-mdx-body">
          <MDXProvider components={components}>
            <MDXRenderer>{body}</MDXRenderer>
          </MDXProvider>
        </Box>
      </Flex>
    </BlogLayout>
  )
}
