import * as React from "react"
import { Box } from "@chakra-ui/react"
import Highlight, { defaultProps as prismProps } from "prism-react-renderer"
import theme from "prism-react-renderer/themes/oceanicNext"

export interface CodeBlockProps {
  children: React.ReactElement
}

export function CodeBlock({ children }: CodeBlockProps): JSX.Element {
  const className = children.props.className || ""
  const matches = className.match(/language-(?<lang>.*)/)
  return (
    <Box className="code-block">
      <Highlight
        {...prismProps}
        code={children.props.children.trim()}
        language={
          matches && matches.groups && matches.groups.lang
            ? matches.groups.lang
            : ""
        }
        theme={theme}
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre className={className} style={{ ...style, padding: "20px" }}>
            {tokens.map((line, i) => (
              <div key={i} {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </pre>
        )}
      </Highlight>
    </Box>
  )
}

export default CodeBlock
